import React from 'react';
import { connect } from 'react-redux';
import { addFormValue } from '../../actions/ResumeForm/actions';
import Section from '../Section/Section';

const category = 'profile';

const Profile = ({ handleValueChange, profile, sectionToDisplay }) => {
    const { description } = profile;

    const handleDescriptionChange = description => {
        handleValueChange({ description }, category);
    };

    return (
        <div>
            {
                sectionToDisplay && (
                    <Section
                        title="Profile"
                        descriptionPlaceholder="Write a paragraph about yourself"
                        handleChange={handleDescriptionChange}
                        textboxValue={description}
                    />
                )
            }
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    handleValueChange: (value, category) =>
        dispatch(addFormValue(value, category)),
});

const mapStateToProps = state => ({
    profile: state.resumeForm.profile || {},
    sectionToDisplay: state.settingsToggler.sectionSetting.Profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
