import React from 'react';
import classNames from 'classnames';
import { TextareaAutosize } from '@material-ui/core';
import styles from './Textbox.module.scss';

const Textbox = ({
    tag = 'input',
    className,
    onChange,
    value,
    placeholder,
    size = 'sm',
    theme = 'primary',
    ...props
}) => {
    const sizes = {
        sm: {
            fontSize: '14px',
        },
        md: {
            fontSize: '18px',
        },
        lg: {
            fontSize: '24px',
        },
        xl: {
            fontSize: '34px',
        },
    };

    const themes = {
        primary: {
            fontWeight: 'bold',
        },
        black: {
            color: '#2e3239',
        },
    };

    const styling = {
        ...sizes[size],
        ...themes[theme],
    };

    const classes = classNames(className, styles.textbox);
    const elementProps = {
        style: styling,
        type: 'text',
        className: classes,
        onChange,
        value,
        placeholder,
        ...props,
    };

    switch (tag) {
        case 'input':
            return <input {...elementProps} />
        case 'textarea':
            return <TextareaAutosize aria-label="minimum height" placeholder={placeholder} {...elementProps} />
        default:
            return;
    }
};

export default Textbox;
