import {
    ADD_FORM_VALUE,
    SUBMITTING_FORM,
    SUBMIT_FORM_SUCCESS,
    SUBMIT_FORM_FAIL,
    LOADING_FORM_DATA,
    LOAD_FORM_DATA_SUCCESS,
    LOAD_FORM_DATA_FAIL,
    CHANGE_TOGGLE,
} from '../actions/ResumeForm/actionTypes';
/*
    store should look like this:

    resumeForm: { 
        personalDetails: { 
            personalDetails-213974329: {
                nationality: 'Singaporean',
            },
            personalDetails-324353253: {
                address: 'Bukit Merah'
            }
        },
        contact: {
            contact-3892738342: {
                email: 'bing@yahoo.com',
            }
        },
        ...categories
            ...categoriesId
                ...itemName: value
    }

*/

const initialState = {
    personalDetails: {},
    experiences: {},
    profile: {},
    contacts: {},
    skills: {},
    educations: {},
    languages: {},
    loadingResume: false,
    savingResume: false,
    toggle: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_FORM_VALUE:
            return {
                ...state,
                ...action.payload,
            };
        case LOADING_FORM_DATA:
            return {
              ...state,
              loadingResume: true,
              toggle: false,
            };
        case LOAD_FORM_DATA_SUCCESS:
            return {
                ...state,
                ...action.resumeData,
                loadingResume: false,
            };
        case LOAD_FORM_DATA_FAIL:
            return {
                ...state,
                loadingResume: false,
            };
        case SUBMITTING_FORM:
            return {
                ...state,
                savingResume: true,
            };
        case SUBMIT_FORM_SUCCESS:
            return {
                ...state,
                savingResume: false,
            };
        case SUBMIT_FORM_FAIL:
            return {
                ...state,
                savingResume: false,
            };
        case CHANGE_TOGGLE:
            return {
              ...state,
              toggle: action.value,
            };
        default:
            return state;
    }
};
