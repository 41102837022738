import React from 'react';
import { connect } from 'react-redux';
import { addFormValue } from '../../actions/ResumeForm/actions';
import ListItem from '../ListItem/ListItem';
import Section from '../Section/Section';

const category = 'contacts';

const contactPlaceholders = {
    Email: 'Enter your email address',
    'Mobile Number': 'Enter your phone number',
    LinkedIn: 'Enter your LinkedIn URL',
    Website: 'Enter your website/showcase URL',
};

const contactDefaultValue = {
    Email: '',
    'Mobile Number': '',
    LinkedIn: '',
    Website: '',
};

const Contact = ({ handleValueChange, sectionToDisplay, contacts }) => {
    const handleChange = (title, value) => {
        handleValueChange({ [title]: value }, category);
    };

    const contactValues = {
        ...contactDefaultValue,
        ...contacts,
    };

    const detailsList = Object.entries(contactValues).map(([key, value]) => {
        return (
            sectionToDisplay[key] && (
                <ListItem
                    key={key}
                    title={key}
                    placeholder={contactPlaceholders[key]}
                    handleChange={handleChange}
                    value={value}
                />
            )
        );
    });
    return (
        <Section title="Contact" requireDescription={false}>
            {detailsList}
        </Section>
    );
};

const mapDispatchToProps = dispatch => ({
    handleValueChange: (value, category) =>
        dispatch(addFormValue(value, category)),
});

const mapStateToProps = state => ({
    contacts: state.resumeForm.contacts || {},
    sectionToDisplay: state.settingsToggler.sectionSetting.contactDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
