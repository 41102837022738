import styles from './SettingsToggler.module.scss';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import DescriptionIcon from '@material-ui/icons/Description';
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { makeStyles } from '@material-ui/core/styles';
import { changeTemplateSetting } from '../../actions/SettingsToggler/actions';
import { SETTINGS_POPOVER_STYLE, TEMPLATES } from '../../util/constants';

const useMuiListItemStyles = makeStyles({
    root: {
        display: 'inline',
    },
});


const SettingsChangeTemplateComponent = (props) => {
    const [templateAnchorEl, setTemplateAnchorEl] = useState(null);
    const templateOpen = Boolean(templateAnchorEl);
    const templateId = templateOpen ? 'template-popover' : undefined;
    const listItemClasses = useMuiListItemStyles();

    const { template, changeTemplate } = props;

    return (
        <div className={styles.settingsDiv}>
            <Typography align="center">Templates</Typography>
            <div
                onClick={event => setTemplateAnchorEl(event.currentTarget)}
                className={classNames(styles.popoverSettings)}
            >
                <DescriptionIcon fontSize="large" className={styles.popoverSettings} />
            </div>
            <Popover
                id={templateId}
                open={templateOpen}
                anchorEl={templateAnchorEl}
                onClose={() => setTemplateAnchorEl(null)}
                {...SETTINGS_POPOVER_STYLE}
            >
                {
                    Object.keys(TEMPLATES).map(templateName => (
                        <ListItem key={templateName} classes={{
                            root: listItemClasses.root,
                        }}>
                            <div
                                className={styles.templateImgDiv}
                                onClick={() => changeTemplate(templateName)}
                            >
                                <img src={TEMPLATES[templateName]}
                                     className={classNames(styles.templateImg, template === templateName ? styles.templateImgSelected : '')}
                                     alt={templateName} />
                                <Grid container direction="row" alignItems="center" className={styles.templateImgGrid}>
                                    <Grid item>
                                        {
                                            template === templateName ? <RadioButtonCheckedIcon color="primary" /> :
                                                <RadioButtonUncheckedIcon color="primary" />
                                        }
                                    </Grid>
                                    <Grid item>
                                        {
                                            templateName
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </ListItem>
                    ))
                }
            </Popover>
        </div>
    );
};


const mapStateToProps = state => ({
    template: state.settingsToggler.templateSetting,
});

const mapDispatchToProps = dispatch => ({
    changeTemplate: (template) => dispatch(changeTemplateSetting(template)),
});


export default connect(mapStateToProps, mapDispatchToProps)(SettingsChangeTemplateComponent);
