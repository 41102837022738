import React from 'react';
import { connect } from 'react-redux';
import ListItem from '../ListItem/ListItem';
import { addFormValue } from '../../actions/ResumeForm/actions';
import Section from '../Section/Section';

const category = 'personalDetails';

const personalDetailsPlaceholders = {
    'Birth date': 'Enter your birth date',
    Nationality: 'Enter your nationality',
    Address: 'Enter your address',
    Marital: 'Enter your marital status',
};

const personalDetailsDefaultValue = {
    'Birth date': '',
    Nationality: '',
    Address: '',
    Marital: '',
};

const PersonalDetails = ({ personalDetails, sectionToDisplay, handleValueChange }) => {
    const handleChange = (title, value) => {
        handleValueChange({ [title]: value }, category);
    };

    const personalDetailsFields = {
        ...personalDetailsDefaultValue,
        ...personalDetails,
    };

    const allDisabled = Object.values(sectionToDisplay).every(toDisplay => toDisplay === false);

    const detailsList = Object.entries(personalDetailsFields).map(
        ([key, value]) => {
            return (
                sectionToDisplay[key] && (
                    <ListItem
                        key={key}
                        title={key}
                        placeholder={personalDetailsPlaceholders[key]}
                        textarea
                        handleChange={handleChange}
                        value={value}
                    />
                )
            );
        },
    );
    return (
        !allDisabled && (
            <Section title="Personal Details" requireDescription={false}>
                {detailsList}
            </Section>
        )
    );
};

const mapDispatchToProps = dispatch => ({
    handleValueChange: (value, category) =>
        dispatch(addFormValue(value, category)),
});

const mapStateToProps = state => ({
    personalDetails: state.resumeForm.personalDetails || {},
    sectionToDisplay: state.settingsToggler.sectionSetting.personalDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);
