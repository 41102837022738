import React, { useRef } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReactToPrint from 'react-to-print';
import { NotificationContainer } from 'react-notifications';
import { submitForm } from '../../actions/ResumeForm/actions';

import styles from './ResumeBuilder.module.scss';
import Headline from '../../components/Headline/Headline';
import Profile from '../../components/Profile/Profile';
import DefaultTemplate from '../../components/Templates/DefaultTemplate';
import PersonalDetails from '../../components/PersonalDetails/PersonalDetails';
import Experience from '../../components/Experience/Experience';
import Education from '../../components/Education/Education';
import Contact from '../../components/Contact/Contact';
import Skills from '../../components/Skills/Skills';
import Languages from '../../components/Languages/Languages';
import SettingsToggler from '../../components/SettingsToggler/SettingsToggler';
import LoadResumeModal from '../../components/LoadResumeModal/LoadResumeModal';
import BasicLeftColumnWiderTemplate from '../../components/Templates/BasicLeftColumnWiderTemplate';

const ResumeBuilder = props => {
    const {
        handleFormSubmit,
        savingResume,
        template,
    } = props;

    const componentRef = useRef();

    const renderTemplate = (template, templateProps) => {
        switch (template) {
            case 'Basic 2':
                return <BasicLeftColumnWiderTemplate {...templateProps} />;
            default:
                return <DefaultTemplate {...templateProps} />;
        }
    };

    const headline = <Headline />;
    const profile = <Profile />;
    const personalDetails = <PersonalDetails />;
    const experience = <Experience />;
    const education = <Education />;
    const contact = <Contact />;
    const skills = <Skills />;
    const languages = <Languages />;

    const templateProps = {
        Headline: headline,
        Profile: profile,
        PersonalDetails: personalDetails,
        Experience: experience,
        Education: education,
        Contact: contact,
        Skills: skills,
        Languages: languages,
        Ref: componentRef,
    };

    return (
        <div className={styles.container}>
            <SettingsToggler />
            <div className={styles.templateContainer}>
                {
                    renderTemplate(template, templateProps)
                }
            </div>
            <div>
                <LoadResumeModal/>
                <Button
                    variant='outlined'
                    color='secondary'
                    onClick={handleFormSubmit}
                    className={styles.marginRight}
                    disabled={savingResume}
                >
                    {savingResume ? 'Saving...' : 'Save'}
                </Button>
                <ReactToPrint
                    trigger={() => (
                        <Button
                            variant='outlined'
                            color='secondary'
                        >
                            <FileCopyIcon className={styles.leftIcon} />
                            Download
                        </Button>
                    )}
                    content={() => componentRef.current}
                />
            </div>
            <div>
                <NotificationContainer />
            </div>
        </div>
    )
};

const mapDispatchToProps = dispatch => {
    return {
        handleFormSubmit: () => dispatch(submitForm()),
    };
};

const mapStateToProps = state => ({
    savingResume: state.resumeForm.savingResume,
    template: state.settingsToggler.templateSetting,
});

export default connect(mapStateToProps, mapDispatchToProps)(ResumeBuilder);
