import React from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import styles from './Section.module.scss';
import Textbox from '../Textbox/Textbox';

const Section = ({
    title,
    requireDescription = true,
    descriptionPlaceholder,
    children,
    allowAddition = false,
    onAdditionClick,
    textboxValue,
    handleChange = () => {},
}) => {
    const handleTextboxChange = e => {
        handleChange(e.target.value);
    };
    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            {requireDescription && (
                <div className={styles.description}>
                    <Textbox
                        theme="black"
                        size="sm"
                        tag="textarea"
                        placeholder={descriptionPlaceholder}
                        rowsMin="3"
                        onChange={handleTextboxChange}
                        value={textboxValue}
                    />
                </div>
            )}
            {children}
            {allowAddition && (
                <div className={styles.addIconContainer}>
                    <AddCircleIcon
                        className={styles.addIcon}
                        onClick={onAdditionClick}
                    />
                </div>
            )}
        </div>
    );
};

export default Section;
