import React from 'react';
import { connect } from 'react-redux';
import ListDetailedItem from '../ListDetailedItem/ListDetailedItem';
import Section from '../Section/Section';
import {addFormValue} from "../../actions/ResumeForm/actions";
import {generateFieldId} from "../../util/util";

const educationDefaultValues = {
    title: '',
    subtitle: '',
    datetime: ''
};

const educationPlaceholders = {
    title: 'Degree / Certificate',
    subtitle: 'Alma Mater',
    datetime: 'From - End'
};

const category = 'educations';

const Education = (props) => {
    const { educations, handleValueChange } = props;

    const addNewEducation = () => {
        const newEducation = {
            [generateFieldId(category)]: {
                ...educationDefaultValues,
            },
        };
        handleValueChange(newEducation, category)
    };

    const handleChange = (id, data) => {
        const newEducation = {
            [id]: {
                ...data,
            },
        };
        handleValueChange(newEducation, category);
    };

    if (Object.keys(educations).length === 0) {
        addNewEducation();
    }

    const mappedDetailedItems = Object.entries(
        educations,
    ).map(([id, { title, subtitle, description, datetime }]) => (
        <ListDetailedItem
            id={id}
            key={id}
            title={title}
            subtitle={subtitle}
            description={description}
            handleChange={handleChange}
            placeholders={educationPlaceholders}
            allowDescription={false}
            datetime={datetime}
        />
    ));

    return (
        <Section
            title="Education"
            requireDescription={false}
            allowAddition
            onAdditionClick={addNewEducation}
        >
            {mappedDetailedItems}
        </Section>
    );
};

const mapDispatchToProps = dispatch => ({
    handleValueChange: (value, category) =>
        dispatch(addFormValue(value, category)),
});

const mapStateToProps = state => ({
    educations: state.resumeForm.educations || {},
});

export default connect(mapStateToProps, mapDispatchToProps)(Education);
