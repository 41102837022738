import React, { useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import { getBackgroundStyleFromColor } from '../../util/util';
import { COLORS, SETTINGS_POPOVER_STYLE } from '../../util/constants';
import { changeColorSetting } from '../../actions/SettingsToggler/actions';
import styles from './SettingsToggler.module.scss';

const SettingsChangeColorComponent = (props) => {
    const [colorAnchorEl, setColorAnchorEl] = useState(null);
    const colorOpen = Boolean(colorAnchorEl);
    const colorId = colorOpen ? 'color-popover' : undefined;

    const { color, changeColor } = props;
    return (
        <div className={styles.settingsDiv}>
            <Typography align="center">Colors</Typography>
            <div
                className={classNames(styles.popoverSettings, styles.colorSettings, styles.colorOption, getBackgroundStyleFromColor(color))}
                onClick={event => setColorAnchorEl(event.currentTarget)}
            />
            <Popover
                id={colorId}
                open={colorOpen}
                anchorEl={colorAnchorEl}
                onClose={() => setColorAnchorEl(null)}
                {...SETTINGS_POPOVER_STYLE}
            >
                {
                    Object.keys(COLORS).map(k => (
                        <ListItem key={k}>
                            <div
                                className={classNames(styles.colorOption, getBackgroundStyleFromColor(COLORS[k]))}
                                onClick={() => {
                                    changeColor(COLORS[k]);
                                    setColorAnchorEl(null);
                                }}
                            >
                                {
                                    color === COLORS[k] && (
                                        <DoneRoundedIcon className={styles.selected} />
                                    )
                                }
                            </div>
                        </ListItem>
                    ))
                }
            </Popover>
        </div>
    );
};


const mapStateToProps = state => ({
    color: state.settingsToggler.colorSetting,
});

const mapDispatchToProps = dispatch => ({
    changeColor: (color) => dispatch(changeColorSetting(color)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsChangeColorComponent);
