import {
    CHANGE_COLOR_SETTING,
    CHANGE_SECTION_SETTING,
    CHANGE_TEMPLATE_SETTING,
    CHANGE_TYPOGRAPHY_SETTING,
} from './actionTypes';

export const changeColorSetting = color => ({ type: CHANGE_COLOR_SETTING, color });
export const changeTypographySetting = typography => ({type: CHANGE_TYPOGRAPHY_SETTING, typography});
export const changeTemplateSetting = template => ({ type: CHANGE_TEMPLATE_SETTING, template });
const changeSectionSetting = payload => ({ type: CHANGE_SECTION_SETTING, payload });


export const toggleSectionSetting = (value, category) => (dispatch, getState) => {
    const { settingsToggler } = getState();
    const sectionSetting = settingsToggler.sectionSetting;

    let updatedSetting = {};
    if (category) {
        const payload = {
            [category]: {
                ...sectionSetting[category],
                ...value,
            },
        };

        updatedSetting = {
            ...sectionSetting,
            ...payload,
        };

    } else {
        updatedSetting = {
            ...sectionSetting,
            ...value,
        };
    }

    dispatch(changeSectionSetting(updatedSetting));
};
