export const COLORS = {
    ORANGE: 'ORANGE',
    MAROON: 'MAROON',
    CYAN: 'CYAN',
    TEAL: 'TEAL',
    BLUE: 'BLUE',
    PURPLE: 'PURPLE',
    BLACK: 'BLACK',
};

export const TYPOGRAPHY = {
    OPEN_SANS: 'Open Sans',
    MONOSPACE: 'Monospace',
};

export const TEMPLATES = {
    'Basic': 'https://interviewer-ai-assets.s3-ap-southeast-1.amazonaws.com/resume-builder/template-images/BasicTemplateDefaultTemplate.jpg',
    'Basic 2': 'https://interviewer-ai-assets.s3-ap-southeast-1.amazonaws.com/resume-builder/template-images/BasicLeftColumnWiderTemplate.jpg',
};

export const SETTINGS_POPOVER_STYLE = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    style: {
        marginTop: '15px',
    },
};
