import React from 'react';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
// import CancelIcon from '@material-ui/icons/Cancel';
import Textbox from '../Textbox/Textbox';
import styles from './ListDetailedItem.module.scss';

const ListDetailedItem = React.memo(
    ({ id, title, subtitle, description, datetime, handleChange = () => {}, allowDescription = true, placeholders = {} }) => {
        const handleValueChange = e => {
            handleChange(id, {
                title,
                subtitle,
                description,
                datetime,
                [e.target.name]: e.target.value,
            });
        };

        return (
            <div className={styles.container}>
                <div className={styles.radioContainer}>
                    <RadioButtonCheckedIcon className={styles.bullet} />
                </div>
                <div className={styles.textboxesContainer}>
                    <Textbox
                        size="md"
                        name="title"
                        placeholder={placeholders['title']}
                        theme="primary"
                        onChange={handleValueChange}
                        value={title}
                    />
                    <div className={styles.datetimeAndLocation}>
                        <Textbox
                            size="sm"
                            name="subtitle"
                            placeholder={placeholders['subtitle']}
                            theme="black"
                            onChange={handleValueChange}
                            value={subtitle}
                        />
                        <Textbox
                            className={styles.datetime}
                            size="sm"
                            name="datetime"
                            placeholder={placeholders['datetime']}
                            theme="black"
                            onChange={handleValueChange}
                            value={datetime}
                        />
                    </div>
                    {allowDescription && (
                        <Textbox
                            size="sm"
                            name="description"
                            tag="textarea"
                            placeholder={placeholders['description']}
                            theme="black"
                            className={styles.description}
                            onChange={handleValueChange}
                            value={description}
                            rowsMin={3}
                        />
                    )}
                </div>
                {/* <div className={styles.cancelContainer}> TODO: delete item from array
                <CancelIcon className={styles.cancelIcon} />
            </div> */}
            </div>
        );
    },
);

export default ListDetailedItem;
