import React from 'react';
import styles from './SettingsToggler.module.scss';
import SettingsChangeTemplateComponent from './SettingsChangeTemplateComponent';
import SettingsChangeSectionComponent from './SettingsChangeSectionComponent';
import SettingsChangeTypographyComponent from './SettingsChangeTypographyComponent';
import SettingsChangeColorComponent from './SettingsChangeColorComponent';


const SettingsToggler = () => {
    return (
        <div className={styles.settingsToggler}>
            <SettingsChangeColorComponent />
            <SettingsChangeTypographyComponent />
            <SettingsChangeSectionComponent />
            <SettingsChangeTemplateComponent />
        </div>
    );
};

export default SettingsToggler;
