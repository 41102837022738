import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => (
    <div className={styles.footer}>
        Powered by
        <br />
        <img
            src="https://interviewer-ai-assets.s3-ap-southeast-1.amazonaws.com/interviewer-ai-5kccn8.png"
            alt="Interviewer.AI Logo"
            className={styles.footerImg}
        />
    </div>
);

export default Footer;
