import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import store from './store/store';
import ResumeBuilder from './pages/ResumeBuilder/ResumeBuilder';
import Footer from './components/PageComponents/Footer';
import Header from './components/PageComponents/Header';
import Theme from './theme/Theme';

import { changeColorSetting } from "./actions/SettingsToggler/actions";

window.store = store;
window.changeColorSetting = changeColorSetting;

ReactDOM.render(
    <React.StrictMode>
        <CssBaseline />
        <MuiThemeProvider theme={Theme}>
            <Provider store={store}>
                <Header />
                <ResumeBuilder />
                <Footer />
            </Provider>
        </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
