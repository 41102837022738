import React from 'react';
import classNames from 'classnames';
import Textbox from '../Textbox/Textbox';
import styles from './ListItem.module.scss';

const ListItem = React.memo(
    ({ title, placeholder, textarea = false, handleChange, value }) => {
        const handleValueChange = e => {
            handleChange(title, e.target.value);
        };
        return (
            <div className={styles.container}>
                <p className={classNames(styles.itemKey)}>{title}</p>
                <Textbox
                    name="value"
                    theme="black"
                    placeholder={placeholder}
                    tag={textarea ? 'textarea' : 'input'}
                    onChange={handleValueChange}
                    value={value}
                />
            </div>
        );
    },
);
export default ListItem;
