import React from 'react';
import { connect } from 'react-redux';
import { addFormValue } from '../../actions/ResumeForm/actions';
import ListDetailedItem from '../ListDetailedItem/ListDetailedItem';
import Section from '../Section/Section';
import { generateFieldId } from '../../util/util';

const experienceDefaultValue = {
    title: '',
    subtitle: '',
    description: '',
    datetime: '',
};

const experiencePlaceholders = {
    title: 'Position / Role',
    subtitle: 'Company',
    description: 'Describe your role here',
    datetime: 'From - End',
};

const category = 'experiences';

const Experience = props => {
    const { experiences, handleValueChange } = props;

    const addNewExperience = () => {
        const newExperience = {
            [generateFieldId(category)]: {
                ...experienceDefaultValue,
            },
        };
        handleValueChange(newExperience, category);
    };

    const handleChange = (id, data) => {
        const newExperience = {
            [id]: {
                ...data,
            },
        };
        handleValueChange(newExperience, category);
    };

    if (Object.keys(experiences).length === 0) {
        addNewExperience();
    }

    const mappedDetailedItems = Object.entries(
        experiences,
    ).map(([id, { title, subtitle, description, datetime }]) => (
        <ListDetailedItem
            id={id}
            key={id}
            title={title}
            subtitle={subtitle}
            description={description}
            datetime={datetime}
            handleChange={handleChange}
            placeholders={experiencePlaceholders}
        />
    ));
    return (
        <Section
            title="Professional Experience"
            requireDescription={false}
            allowAddition
            onAdditionClick={addNewExperience}
        >
            {mappedDetailedItems}
        </Section>
    );
};

const mapDispatchToProps = dispatch => ({
    handleValueChange: (value, category) =>
        dispatch(addFormValue(value, category)),
});
const mapStateToProps = state => ({
    experiences: state.resumeForm.experiences || {},
});

export default connect(mapStateToProps, mapDispatchToProps)(Experience);
