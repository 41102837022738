import styles from './SettingsToggler.module.scss';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import ViewListIcon from '@material-ui/icons/ViewList';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toggleSectionSetting } from '../../actions/SettingsToggler/actions';
import { makeStyles } from '@material-ui/core/styles';
import { SETTINGS_POPOVER_STYLE } from '../../util/constants';

const usePopoverStyles = makeStyles({
    paper: {
        padding: '15px',
    },
});

const SettingsChangeSectionComponent = (props) => {
    const [sectionAnchorEl, setSectionAnchorEl] = useState(null);
    const sectionOpen = Boolean(sectionAnchorEl);
    const sectionId = sectionOpen ? 'section-popover' : undefined;
    const classes = usePopoverStyles();

    const { section, toggleSection } = props;
    return (
        <div className={styles.settingsDiv}>
            <Typography align="center">Sections</Typography>
            <div
                onClick={event => setSectionAnchorEl(event.currentTarget)}
                className={classNames(styles.popoverSettings)}
            >
                <ViewListIcon fontSize="large" className={styles.popoverSettings} />
            </div>
            <Popover
                id={sectionId}
                open={sectionOpen}
                anchorEl={sectionAnchorEl}
                onClose={() => setSectionAnchorEl(null)}
                {...SETTINGS_POPOVER_STYLE}
                classes={{
                    paper: classes.paper,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <Typography variant="h6">Personal details</Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox name="Birth date" color="primary"
                                                   checked={section.personalDetails['Birth date']}
                                                   onChange={
                                                       () => toggleSection({
                                                           'Birth date': !section.personalDetails['Birth date']
                                                       }, 'personalDetails')
                                                   } />}
                                label="Birth date"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox name="Nationality" color="primary"
                                                   checked={section.personalDetails.Nationality}
                                                   onChange={
                                                       () => toggleSection({
                                                           Nationality: !section.personalDetails.Nationality
                                                       }, 'personalDetails')
                                                   } />}
                                label="Nationality"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox name="Address" color="primary"
                                                   checked={section.personalDetails.Address}
                                                   onChange={
                                                       () => toggleSection({
                                                           Address: !section.personalDetails.Address
                                                       }, 'personalDetails')
                                                   } />}
                                label="Address"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox name="Marital" color="primary"
                                                   checked={section.personalDetails.Marital}
                                                   onChange={
                                                       () => toggleSection({
                                                           Marital: !section.personalDetails.Marital
                                                       }, 'personalDetails')
                                                   }/>}
                                label="Marital Status"
                            />
                        </FormGroup>
                        <Divider className={styles.dividerMargin} />
                        <Typography variant="h6">Contact</Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox name="Email" color="primary" checked disabled
                                                   className={styles.checkboxDisabled} />}
                                label="Email"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox name="Mobile Number" color="primary"
                                                   checked={section.contactDetails['Mobile Number']}
                                                   onChange={
                                                       () => toggleSection({
                                                           'Mobile Number': !section.contactDetails['Mobile Number']
                                                       }, 'contactDetails')
                                                   }/>}
                                label="Mobile Number"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox name="LinkedIn" color="primary"
                                                   checked={section.contactDetails.LinkedIn}
                                                   onChange={
                                                       () => toggleSection({
                                                           LinkedIn: !section.contactDetails.LinkedIn
                                                       }, 'contactDetails')
                                                   }/>}
                                label="LinkedIn"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox name="Website" color="primary"
                                                   checked={section.contactDetails.Website}
                                                   onChange={
                                                       () => toggleSection({
                                                           Website: !section.contactDetails.Website
                                                       }, 'contactDetails')
                                                   }/>}
                                label="Website"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography variant="h6">General Info</Typography>
                        <Grid container>
                            <Grid item xs={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox name="Name" color="primary" defaultChecked disabled
                                                           className={styles.checkboxDisabled} />}
                                        label="Name"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox name="Experience" color="primary" defaultChecked disabled
                                                           className={styles.checkboxDisabled} />}
                                        label="Experience"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox name="Education" color="primary" defaultChecked disabled
                                                           className={styles.checkboxDisabled} />}
                                        label="Education"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox name="Profession" color="primary"
                                                           checked={section.Profession}
                                                           onChange={
                                                               () => toggleSection({
                                                                   Profession: !section.Profession })
                                                           } />}
                                        label="Profession"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox name="Profile" color="primary"
                                                           checked={section.Profile}
                                                           onChange={
                                                               () => toggleSection({
                                                                   Profile: !section.Profile })
                                                           }/>}
                                        label="Profile Summary"
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Divider className={styles.dividerMargin} />
                        <Typography variant="h6">Skills Section</Typography>
                        <Typography variant="caption">Ideal for listing the most relevant skills for the type of job
                            you'll be applying.</Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox name="Skills" color="primary" checked={section.Skills}
                                                   onChange={
                                                       () => toggleSection({
                                                           Skills: !section.Skills })
                                                   }/>}
                                label="Skills"
                            />
                        </FormGroup>
                        <Divider className={styles.dividerMargin} />
                        <Typography variant="h6">Language Section</Typography>
                        <Typography variant="caption">Specify proficiency of language.</Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox name="Language" color="primary" checked={section.Language}
                                                   onChange={
                                                       () => toggleSection({
                                                           Language: !section.Language })
                                                   }/>}
                                label="Language"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Popover>
        </div>
    );
};


const mapStateToProps = state => ({
    section: state.settingsToggler.sectionSetting,
});

const mapDispatchToProps = dispatch => ({
    toggleSection: (value, category) => dispatch(toggleSectionSetting(value, category)),
});


export default connect(mapStateToProps, mapDispatchToProps)(SettingsChangeSectionComponent);
