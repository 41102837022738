import React, { useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { SETTINGS_POPOVER_STYLE, TYPOGRAPHY } from '../../util/constants';
import { changeTypographySetting } from '../../actions/SettingsToggler/actions';
import styles from './SettingsToggler.module.scss';

const SettingsChangeTypographyComponent = (props) => {
    const [typoAnchorEl, setTypoAnchorEl] = useState(null);
    const typoOpen = Boolean(typoAnchorEl);
    const typoId = typoOpen ? 'typo-popover' : undefined;
    const { typography, changeTypography } = props;
    return (
        <div className={styles.settingsDiv}>
            <Typography align="center">Typography</Typography>
            <div
                onClick={event => setTypoAnchorEl(event.currentTarget)}
                className={classNames(styles.popoverSettings, styles.typoSettings)}
            >
                {typography}
                <ArrowDropDownIcon />
            </div>
            <Popover
                id={typoId}
                open={typoOpen}
                anchorEl={typoAnchorEl}
                onClose={() => setTypoAnchorEl(null)}
                {...SETTINGS_POPOVER_STYLE}
            >
                {
                    Object.keys(TYPOGRAPHY).map(k => (
                        <ListItem key={k}>
                            <div
                                onClick={() => {
                                    changeTypography(TYPOGRAPHY[k]);
                                    setTypoAnchorEl(null);
                                }}
                                className={styles.popoverSettings}
                            >
                                {TYPOGRAPHY[k]}
                            </div>
                        </ListItem>
                    ))
                }
            </Popover>
        </div>
    );
};


const mapStateToProps = state => ({
    typography: state.settingsToggler.typographySetting,
});

const mapDispatchToProps = dispatch => ({
    changeTypography: (typography) => dispatch(changeTypographySetting(typography)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsChangeTypographyComponent);
