import React from 'react';
import { connect } from 'react-redux';
import { addFormValue } from '../../actions/ResumeForm/actions';
import styles from './Headline.module.scss';
import Textbox from '../Textbox/Textbox';

const category = 'profile';

const Headline = ({ handleValueChange, profile, sectionToDisplay }) => {
    const { name, profession } = profile;
    const handleChange = e => {
        handleValueChange({ [e.target.name]: e.target.value }, category);
    };

    return (
        <div className={styles.container}>
            <Textbox
                name="name"
                placeholder="Name"
                size="xl"
                onChange={handleChange}
                value={name || ''}
            />
            {
                sectionToDisplay && (
                    <Textbox
                        name="profession"
                        theme="black"
                        placeholder="Profession"
                        size="lg"
                        value={profession || ''}
                        onChange={handleChange}
                    />
                )
            }
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    handleValueChange: (value, category) =>
        dispatch(addFormValue(value, category)),
});

const mapStateToProps = state => ({
    profile: state.resumeForm.profile || {},
    sectionToDisplay: state.settingsToggler.sectionSetting.Profession,
});

export default connect(mapStateToProps, mapDispatchToProps)(Headline);
