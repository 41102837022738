import { COLORS, TYPOGRAPHY } from '../util/constants';
import {
    CHANGE_COLOR_SETTING,
    CHANGE_SECTION_SETTING,
    CHANGE_TYPOGRAPHY_SETTING,
    CHANGE_TEMPLATE_SETTING,
} from '../actions/SettingsToggler/actionTypes';

const initialState = {
    colorSetting: COLORS.CYAN,
    typographySetting: TYPOGRAPHY.OPEN_SANS,
    sectionSetting: {
        Profession: true,
        Profile: true,
        personalDetails: {
            'Birth date': true,
            Nationality: true,
            Address: true,
            Marital: true,
        },
        contactDetails: {
            Email: true,
            'Mobile Number': true,
            LinkedIn: true,
            Website: true,
        },
        Skills: true,
        Language: true,
    },
    templateSetting: 'Basic',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_COLOR_SETTING:
            return {
                ...state,
                colorSetting: action.color,
            };
        case CHANGE_TYPOGRAPHY_SETTING:
            return {
                ...state,
                typographySetting: action.typography,
            };
        case CHANGE_SECTION_SETTING:
            return {
                ...state,
                sectionSetting: action.payload,
            };
        case CHANGE_TEMPLATE_SETTING:
            return {
                ...state,
                templateSetting: action.template,
            };
        default:
            return state;
    }
};
