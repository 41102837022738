import React from 'react';
import classNames from 'classnames';
import styles from './DefaultTemplate.module.scss';
import { connect } from 'react-redux';
import { getStyleFromColor, getTextStyleFromTypography } from '../../util/util';

const DefaultTemplate = ({
    Headline,
    Profile,
    PersonalDetails,
    Experience,
    Education,
    Contact,
    Skills,
    Languages,
    Ref,
    color,
    typography,
}) => {
    return (
        <div className={classNames(
            styles.container,
            getStyleFromColor(color),
            getTextStyleFromTypography(typography),
        )} ref={Ref} id="div-to-print">
            <div className={styles.headline}>{Headline}</div>
            <div className={styles.divider}>
                <div className={styles.left}>
                    {PersonalDetails}
                    {Contact}
                    {Languages}
                </div>
                <div className={styles.right}>
                    {Profile}
                    {Experience}
                    {Education}
                    {Skills}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    color: state.settingsToggler.colorSetting,
    typography: state.settingsToggler.typographySetting,
});

export default connect(mapStateToProps)(DefaultTemplate);
