import React from 'react';
import classNames from 'classnames';
import styles from './BasicLeftColumnWiderTemplate.module.scss';
import { connect } from 'react-redux';
import { getStyleFromColor, getTextStyleFromTypography } from '../../util/util';

const BasicLeftColumnWiderTemplate = ({
     Headline,
     Profile,
     PersonalDetails,
     Experience,
     Education,
     Contact,
     Skills,
     Languages,
     Ref,
     color,
     typography,
 }) => {
    return (
        <div className={classNames(
            styles.container,
            getStyleFromColor(color),
            getTextStyleFromTypography(typography),
        )} ref={Ref} id="div-to-print">
            <div className={styles.headline}>{Headline}</div>
            <div className={styles.divider}>
                <div className={styles.left}>
                    {Profile}
                    {Experience}
                    {Education}
                    {Skills}
                </div>
                <div className={styles.right}>
                    {PersonalDetails}
                    {Contact}
                    {Languages}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    color: state.settingsToggler.colorSetting,
    typography: state.settingsToggler.typographySetting,
});

export default connect(mapStateToProps)(BasicLeftColumnWiderTemplate);
