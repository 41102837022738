import React from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
} from '@material-ui/core';
import { changeModalToggle, getFormData } from '../../actions/ResumeForm/actions';
import styles from './LoadResumeModal.module.scss'

const LoadResumeModal = (props) => {
    const {
        handleGetFormData,
        loadingResume,
        handleModalToggle,
        toggle,
    } = props;

    const handleModalSubmit = () => {
        const email  = document.getElementById('email').value;
        handleGetFormData(email);
    };

    return (
        <div
            className={styles.modalContainer}
        >
            <Button
                variant='outlined'
                color='secondary'
                onClick={handleModalToggle}
                disabled={loadingResume}
            >
                { loadingResume ? 'Loading...' : 'Load'}
            </Button>
            <Dialog open={toggle} onClose={handleModalToggle} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Load Resume</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To load a resume, please enter an email address here.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalToggle} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleModalSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    handleGetFormData: (email) => dispatch(getFormData(email)),
    handleModalToggle: () => dispatch(changeModalToggle()),
});

const mapStateToProps = state => ({
    loadingResume: state.resumeForm.loadingResume,
    toggle: state.resumeForm.toggle,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadResumeModal);
