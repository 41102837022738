import styles from '../components/SettingsToggler/SettingsToggler.module.scss';
import themeStyle from '../theme/theme.module.scss';
import { COLORS, TYPOGRAPHY } from './constants';

export const generateFieldId = category =>
    `${category}-${Math.floor(Math.random() * new Date().getTime())}`;


export const getBackgroundStyleFromColor = color => {
    switch (color) {
        case COLORS.CYAN:
            return styles.colorCyan;
        case COLORS.BLUE:
            return styles.colorBlue;
        case COLORS.TEAL:
            return styles.colorTeal;
        case COLORS.MAROON:
            return styles.colorMaroon;
        case COLORS.PURPLE:
            return styles.colorPurple;
        case COLORS.BLACK:
            return styles.colorBlack;
        case COLORS.ORANGE:
        default:
            return styles.colorOrange;
    }
};

export const getStyleFromColor = color => {
    switch (color) {
        case COLORS.CYAN:
            return themeStyle.styleCyan;
        case COLORS.BLUE:
            return themeStyle.styleBlue;
        case COLORS.TEAL:
            return themeStyle.styleTeal;
        case COLORS.MAROON:
            return themeStyle.styleMaroon;
        case COLORS.PURPLE:
            return themeStyle.stylePurple;
        case COLORS.BLACK:
            return themeStyle.styleBlack;
        case COLORS.ORANGE:
        default:
            console.log(themeStyle.styleOrange);
            return themeStyle.styleOrange;
    }
};

export const getTextStyleFromTypography = typography => {
    switch (typography) {
        case TYPOGRAPHY.MONOSPACE:
            return themeStyle.styleTypographyMonospace;
        case TYPOGRAPHY.OPEN_SANS:
        default:
            return themeStyle.styleTypographyOpenSans;

    }
};

export const convertDataFromArrayToObject = (category, dataArray) => {
  const data = {};
  dataArray.map( item => {
     data[generateFieldId(category)] = item;
     return true;
  });
  return data;
};