import React from 'react';
import Textbox from '../Textbox/Textbox';
import styles from './ListPair.module.scss';

const ListPair = React.memo(({ id, title, level, handleChange, placeholders }) => {
    const handleValueChange = e => {
        handleChange(id, {
            title,
            level,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div className={styles.container}>
            <Textbox
                theme="black"
                tag="textarea"
                rowsMin={1}
                size="sm"
                name="title"
                placeholder={placeholders['title']}
                onChange={handleValueChange}
                value={title}
            />
            <Textbox
                theme="primary"
                size="sm"
                name="level"
                placeholder={placeholders['level']}
                onChange={handleValueChange}
                value={level}
            />
        </div>
    );
});

export default ListPair;
