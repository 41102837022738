import 'es6-promise/auto';
import fetch from 'isomorphic-fetch';
import clientConfig from '../../config/config';
import { toast } from '../../components/Toast/Toast';
import { convertDataFromArrayToObject } from '../../util/util';
import {
    LOADING_FORM_DATA,
    LOAD_FORM_DATA_FAIL,
    LOAD_FORM_DATA_SUCCESS,
    SUBMITTING_FORM,
    SUBMIT_FORM_SUCCESS,
    SUBMIT_FORM_FAIL,
    ADD_FORM_VALUE, CHANGE_TOGGLE,
} from './actionTypes';

const config = clientConfig.default;

const submittingForm = () => ({ type: SUBMITTING_FORM });
const submitFormSuccess = () =>({ type: SUBMIT_FORM_SUCCESS });
const submitFormFail = () => ({ type: SUBMIT_FORM_FAIL });
const addValue = payload => ({ type: ADD_FORM_VALUE, payload });
const loadingFormData = () => ({ type: LOADING_FORM_DATA });
const loadFormDataSuccess = resumeData => ({ type: LOAD_FORM_DATA_SUCCESS, resumeData });
const loadFormDataFail = () => ({ type: LOAD_FORM_DATA_FAIL });
const changeToggle = (value) => ({ type: CHANGE_TOGGLE, value });

export const changeModalToggle = () => (dispatch, getState) => {
    const { toggle } = getState().resumeForm;
    dispatch(changeToggle(!toggle));
};

export const getFormData = (email) => async (dispatch) => {

    const re = /^(([^<>()[\]\\.,;:\s@/\\/"]+(\.[^<>()[\]\\.,;:\s@/\\/"]+)*)|(\/".+\/"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) {
        toast('error', 'Email is not valid');
        return false;
    }

    dispatch(loadingFormData());
    try {
      const req = await fetch(`${config.DOMAIN}/api/firebase/resume/${email}`);

      if(req.status === 404) {
          throw new Error('No Resume Found');
      }

      if (!req.ok) {
         throw new Error('Error retrieving resume');
      }

      const res = await req.json();
      const {
          personalDetails,
          experiences,
          profile,
          contacts,
          skills,
          educations,
          languages,
      } = res.candidateResumeData;

      toast('success', `Resume retrieved successfully for ${email}`);
      dispatch(loadFormDataSuccess({
          personalDetails,
          experiences: convertDataFromArrayToObject('experiences', experiences),
          profile,
          contacts,
          skills: convertDataFromArrayToObject('skills', skills),
          educations: convertDataFromArrayToObject('educations', educations),
          languages: convertDataFromArrayToObject('languages', languages),
      }));
    } catch (e) {
        const errorMessage = e.message;
        console.log(errorMessage);
        toast('error', errorMessage);
        dispatch(loadFormDataFail());
    }
};

export const submitForm = () => async (dispatch, getState) => {
    dispatch(submittingForm());
    try {
        const {
            personalDetails,
            experiences,
            profile,
            contacts,
            skills,
            educations,
            languages,
        } = getState().resumeForm;

        const { Email } = contacts;
        const re = /^(([^<>()[\]\\.,;:\s@/\\/"]+(\.[^<>()[\]\\.,;:\s@/\\/"]+)*)|(\/".+\/"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(Email)) {
            toast('error', 'Email is not valid');
            dispatch(submitFormFail());
            return false;
        }

        // perform API call
        const req = await fetch(`${config.DOMAIN}/api/firebase/resume`,{
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                email: contacts.Email,
                data: {
                    personalDetails,
                    experiences: Object.values(experiences),
                    profile,
                    contacts,
                    skills: Object.values(skills),
                    educations: Object.values(educations),
                    languages: Object.values(languages),
                }
            }),
        });

        if(!req.ok){
          throw new Error('Error saving resume');
        }

        toast('success', `Resume saved successfully for ${contacts.Email}`);
        dispatch(submitFormSuccess());
    } catch (e) {
       console.log(e);
       toast('error', e.message);
       dispatch(submitFormFail());
    }
};

export const addFormValue = (value, category) => (dispatch, getState) => {
    const { resumeForm } = getState();
    const existingFormData = resumeForm[category];
    const data = {
        [category]: {
            ...existingFormData,
            ...value,
        },
    };

    dispatch(addValue(data));
};
