import React from 'react';
import { connect } from 'react-redux';
import ListPair from '../ListPair/ListPair';
import Section from '../Section/Section';
import styles from './Skills.module.scss';
import {addFormValue} from "../../actions/ResumeForm/actions";
import {generateFieldId} from "../../util/util";

const category =  'skills';

const skillsDefaultValues = {
    title: '',
    level: '',
};

const skillsPlaceholders = {
    title: 'Skill Name',
    level: 'Beginner',
};

const Skills = (props) => {
    const { skills, handleValueChange, sectionToDisplay } = props;

    const addNewSkills = () => {
        const newSkill = {
            [generateFieldId(category)]: {
                ...skillsDefaultValues,
            },
        };
        handleValueChange(newSkill, category);
    };

    const handleChange = (id, data) => {
        const newSkill = {
            [id]: {
                ...data,
            },
        };
        handleValueChange(newSkill, category);
    };

    if (Object.keys(skills).length === 0) {
        addNewSkills();
    }

    const mappedSkills = Object.entries(
        skills,
    ).map(([id, { title, level }]) => (
        <div className={styles.skillset} key={id}>
            <ListPair
              id={id}
              title={title}
              level={level}
              handleChange={handleChange}
              placeholders={skillsPlaceholders}
            />
        </div>
    ));
    return (
        sectionToDisplay && (
            <Section
                title="Skills"
                requireDescription={false}
                allowAddition
                onAdditionClick={addNewSkills}
            >
                {mappedSkills}
            </Section>
        )
    );
};

const mapDispatchToProps = dispatch => ({
    handleValueChange: (value, category) =>
        dispatch(addFormValue(value, category)),
});

const mapStateToProps = state => ({
    skills: state.resumeForm.skills || {},
    sectionToDisplay: state.settingsToggler.sectionSetting.Skills,
});

export default connect(mapStateToProps, mapDispatchToProps)(Skills);
