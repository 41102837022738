import React from 'react';
import { connect } from 'react-redux';
import ListPair from '../ListPair/ListPair';
import Section from '../Section/Section';
import styles from './Languages.module.scss';
import {generateFieldId} from "../../util/util";
import {addFormValue} from "../../actions/ResumeForm/actions";

const category = 'languages';

const languagesDefaultValues = {
    title: '',
    level: '',
};

const languagesPlaceholders = {
    title: 'Language',
    level: 'Fluent',
};

const Languages = (props) => {
    const { languages, handleValueChange, sectionToDisplay } = props;

    const addNewLanguage = () => {
        const newLanguage = {
            [generateFieldId(category)]: {
                ...languagesDefaultValues,
            },
        };
        handleValueChange(newLanguage, category);
    };

    const handleChange = (id, data) => {
        const newLanguage = {
            [id]: {
                ...data,
            },
        };
        handleValueChange(newLanguage, category);
    };

    if (Object.keys(languages).length === 0) {
        addNewLanguage();
    }

    const mappedLanguages = Object.entries(
      languages,
    ).map(([id, { title, level }]) => (
        <div className={styles.pair} key={id}>
            <ListPair
                id={id}
                title={title}
                level={level}
                handleChange={handleChange}
                placeholders={languagesPlaceholders}
            />
        </div>
    ));

    return (
        sectionToDisplay && (
            <Section
                title="Languages"
                requireDescription={false}
                allowAddition
                onAdditionClick={addNewLanguage}
            >
                {mappedLanguages}
            </Section>
        )
    );
};

const mapDispatchToProps = dispatch => ({
    handleValueChange: (value, category) =>
        dispatch(addFormValue(value, category)),
});

const mapStateToProps = state => ({
    languages: state.resumeForm.languages || {},
    sectionToDisplay: state.settingsToggler.sectionSetting.Language,
});

export default connect(mapStateToProps, mapDispatchToProps)(Languages);
